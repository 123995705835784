import fetch from '@/utils/fetch'

// 获取用户预约列表
export function getAppointmentUsertList(query) {
  return fetch({
    url: '/distributor/app_datas',
    method: 'get',
    params: query
  })
}
// 添加店铺预约信息
export function saveShopMessage(data,query = {}) {
  return fetch({
    url: `/distributor/add_appointment?distributor_id=${data.distributor_id}&hd_dates=${data.hd_dates}&activity_name=${data.activity_name}&description=${data.description}`,
    method: 'post',
    params: query
  })
}
// 获取店铺预约信息列表
export function getAppiontmentShopList(data,query = {}) {
  return fetch({
    url: `/distributor/app_activitys?types=${data.types}&page=${data.page}&pageSize=${data.pageSize}`,
    method: 'get',
    params: query
  })
}
// 获取就诊人列表
export function getPatientList(data,query = {}) {
  return fetch({
    url: `/drug_register/getlist?page=${data.page}&pageSize=${data.pageSize}`,
    method: 'get',
    params: query
  })
}
// 获取就诊需求列表
export function getConsultationDemandList(query = {}) {
  return fetch({
    url: `/openmall/getlist`,
    method: 'get',
    params: query
  })
}
// 获取就诊需求详情
export function getConsultationDemandDetail(id,query = {}) {
  return fetch({
    url: `/openmall/info/${id}`,
    method: 'get',
    params: query
  })
}
// 获取就诊详情
export function getMedicalRecord(id,query = {}) {
  return fetch({
    url: `/openmall/visit_details/${id}`,
    method: 'get',
    params: query
  })
}

// 获取稀缺药列表
export function getScarcemedicineList(query = {}) {
  return fetch({
    url: '/member/drug_appointment/getlist',
    method: 'get',
    params: query
  })
}

// 处理稀缺药预约
export function getScarcemedicineSet(id,query = {}) {
  return fetch({
    url: `/member/drug_appointment/change_status/${id}`,
    method: 'post',
    params: query
  })
}

// 获取用户反馈列表
export function getUserfeedbackList(query = {}) {
  return fetch({
    url: '/member/feedback/getlist',
    method: 'get',
    params: query
  })
}

// 处理用户反馈
export function getUserfeedbackSet(id,query = {}) {
  return fetch({
    url: `/member/feedback/change_status/${id}`,
    method: 'post',
    params: query
  })
}

// 处方审核
export function prescriptionReview(id,query = {}) {
  return fetch({
    url: `/openmall/prescription/review/${id}`,
    method: 'put',
    params: query
  })
}
